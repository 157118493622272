import React from "react"
import Layout from "../_components/Layout/Layout"
import MainTitle from "../_components/MainTitle/MainTitle"

const Contacts = () => {
    return (
        <Layout title="Contacts">
            <MainTitle>Contact us</MainTitle>

            <div className="contact-us">
                <span>How to contact us</span>
                <a className="contact-email" href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                    {process.env.GATSBY_CONTACT_EMAIL}
                </a>
            </div>
        </Layout>
    )
}

export default Contacts
